import React, { useContext, useEffect, useState } from 'react';
import { LanguageContext } from '../Context/LanguageContext';
import AOS from 'aos';
import 'aos/dist/aos.css';


function ServiceIcon({ text, icon, link, subtitle ,description}) {
  const { texts } = useContext(LanguageContext);
  // Encode the additional data as query parameters
  const queryParams = new URLSearchParams({ text, subtitle , description }).toString();

  const handleClick = () => {
     window.location.href = `/services/stationery`;
  };
  const [finalTitle, setFinalTitle] = useState(text); 

  useEffect(()=>{
   if(text=='Fabric' || text=="کوتاڵ"){
    setFinalTitle(texts.fabric);
   }else if(text=='Carpet' || text=="فەرش"){
    setFinalTitle(texts.carpet);
   }else {
    setFinalTitle(texts.motorparts);
   }
  })


  return (
    <div onClick={(text=='Stationery' || text=="قڕتاسیە")?handleClick:()=>{}}
      className='flex flex-col items-center justify-between mb-5 md:w-60 w-40 cursor-pointer mr-5'
    >
      <img className={`md:w-40 w-20 md:h-40 h-20 object-cover`}
        data-aos='fade-down'
      src={icon} alt="icon" />
      <h1 
      className='text-black md:text-xl font-semibold mt-5'
      data-aos='fade-down'
      >{finalTitle}</h1>
    </div>
  );
}

export default ServiceIcon;
