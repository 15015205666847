import React, { useEffect } from 'react'

import { LanguageContext } from '../Context/LanguageContext';
import  { useContext } from 'react';
import ServiceIcon from '../components/ServiceIcon';

import service1 from '../assets/icons/carpet.jpg';
import service2 from '../assets/icons/fabric.jpg';
import service3 from '../assets/All/11.jpg';

import AOS from 'aos';
import 'aos/dist/aos.css';

function Services() {
    const { texts } = useContext(LanguageContext);

  return (
    <div    id='services' className='flex flex-col max-w-[1300px]  mt-20 m-auto  items-center justify-center pb-10 text-center  pr-4 pl-4'>
              <h1
        className="text-black font-bold sm:text-3xl text-lg "
        data-aos="fade-zoom-in" data-aos-offset="200" data-aos-easing="ease-in-sine" data-aos-duration="600"
        > {texts.services} </h1>
       <hr
        className="border-primary w-20 mt-3 sm:mb-16 mb-7"
        data-aos="fade-zoom-in" data-aos-offset="200" data-aos-easing="ease-in-sine" data-aos-duration="600"
        />
        <h1 className='text-black font-semibold sm:text-lg text-sm mb-6'
          data-aos='fade-down'
        >
         {texts.services_subtitle}
          </h1>


        <div className='w-full flex   justify-center items-start mb-5 '>
         
          <ServiceIcon
                     
          text={texts.fabric} icon={service2} />

          <ServiceIcon text={texts.carpet} icon={service1} />

          <ServiceIcon text={texts.motorparts} icon={service3} />

        </div>
   </div>
  )
}

export default Services