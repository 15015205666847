import React, { useContext, useEffect, useRef } from "react";
import { LanguageContext } from '../Context/LanguageContext';
import bg_1 from "../assets/All/1.jpg"
import bg_2 from "../assets/All/2.jpg"
import bg_3 from "../assets/All/3.jpg"
import bg_4 from "../assets/All/4.jpg"

import AOS from 'aos';
import 'aos/dist/aos.css';
const Hero = () => {
  const { texts } = useContext(LanguageContext);
  const imageRef = useRef(null);
  const currentIndexRef = useRef(0);

  const images = [bg_1,bg_2,bg_3,bg_4];

  useEffect(() => {
    const interval = setInterval(() => {
      nextImage();
    }, 2500);

    return () => {
      clearInterval(interval);
    };
  }, []);

  const nextImage = () => {
    currentIndexRef.current = (currentIndexRef.current + 1) % images.length;
    imageRef.current.src = images[currentIndexRef.current];
  };



  return (
    <div className="w-full h-[100%]">
      <img className="w-screen h-screen object-cover brightness-50" ref={imageRef} src={images[0]} alt="Slideshow" />
      <div className="absolute top-0 left-0 bottom-0 right-0 text-center z-10 flex flex-col justify-center items-center "  >
        <h1 className="text-white font-bold text-2xl mr-1 ml-1"
        data-aos="fade-zoom-in" data-aos-offset="200" data-aos-easing="ease-in-sine" data-aos-duration="600"
        >
          {texts.hero_title}
        </h1>
        <h1 className="text-white font-semibold mt-5"
          data-aos='fade-down'
        >
          {texts.hero_subtitle}
        </h1>
        <a href="/about" className="mt-14 bg-primary flex justify-center items-center text-white font-bold rounded w-56 h-12 hover:bg-gray-700 hover:text-white hover:border-white hover:border"
         data-aos='fade-up'
        >
          {texts.about_us}
        </a>
      </div>
    </div>
  );
};

export default Hero;
