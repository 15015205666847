import React, { useState, useEffect } from 'react';
import { AiOutlineMenu, AiOutlineClose } from 'react-icons/ai';
import { useContext } from 'react';
import { LanguageContext } from '../Context/LanguageContext';
import { useLocation, useNavigate,Link} from 'react-router-dom';
import logo_image from '../assets/icons/appicon.png'

const Navbar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const path = location.pathname;


  const [nav, setNav] = useState(false);
  const [scrollMove, setScrollMove] = useState(false);
  const [color, setColor] = useState('transparent');
  const [textColor, setTextColor] = useState('white');


  const handleNav = () => {
    setNav(!nav);
  };


  
  const scrollToSection = (sectionId) => {
    if(sectionId == '/'){
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }

    const section = document.getElementById(sectionId);
    if (section) {
      
      const yOffset = -100; // Adjust this value to set the margin
      const y = section.getBoundingClientRect().top + window.pageYOffset + yOffset;
      window.scrollTo({ top: y, behavior: 'smooth' });
      handleNav(); // Close the mobile menu after navigation
    }
  };

  useEffect(() => {

    if(path=='/'){
      setTextColor('white')
    }else{
      setTextColor('black')
    }
    const changeColor = () => {
    if (window.scrollY >= 90) {
        setColor('rgba(0, 0, 0, 0.5)');
   
          setTextColor('white') 

      } else {
        setColor('transparent');
        if(path!='/'){
          setTextColor('black') 
        }else{
          setTextColor('white')
        } 
      }
    };
  
    const handleScroll = () => {
      changeColor();
      setScrollMove(window.scrollY >= 90);
    };
  
    window.addEventListener('scroll', handleScroll);
  
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  

  const { texts, language, changeLanguage } = useContext(LanguageContext);

  const toggleLanguage = (lang) => {
    changeLanguage(lang);
  };

  return (
    <nav
      style={{ backgroundColor: `${color}` }}
      className='fixed left-0 top-0 w-full z-40 ease-in duration-300'
    >
      <div className='max-w-[1300px] m-auto flex justify-between items-center p-4 text-white'>
        <a href='/'><img src={logo_image} className='w-16 h-16 object-cover pt-3 flex items-center justify-items-center'/></a>

        <ul style={{ color: `${textColor}` }} className='hidden sm:flex items-center'>
        <li className='p-4 text-lg font-bold hover:text-primary'>
            {path!='/'?<a href='/'>{texts.home}</a>: <Link onClick={() => scrollToSection('/')}>{texts.home}</Link>}
          </li>
          <li className='p-4 text-lg font-bold hover:text-primary'>
            {path!='/'?<a href='/about'>{texts.about}</a>: <Link onClick={() => scrollToSection('about')}>{texts.about}</Link>}
          </li>
          <li className='p-4 text-lg font-bold hover:text-primary'>
          {path!='/'?<a href='/'>{texts.services}</a>:<Link onClick={() => scrollToSection('services')}>{texts.services}</Link> }
          </li>
          <li className='p-4 text-lg font-bold hover:text-primary'>
          <Link onClick={() => scrollToSection('contact')}>{texts.contact}</Link>
          </li>
          <li className={`${language === 'en' ? 'ml-2' : 'mr-2'}`}>
            <button
              className={`border border-primary  font-bold w-14 h-8 ${
                language === 'en' ? 'bg-primary rounded-l-md text-white' : `rounded-r-md text-${textColor}`
              } `}
              onClick={() => toggleLanguage('en')}
            >
              EN
            </button>

            <button
              className={`border border-primary  font-bold w-14 h-8 ${
                language === 'ar' ? 'bg-primary rounded-l-md text-white' : `rounded-r-md text-${textColor}`
              }`}
              onClick={() => toggleLanguage('ar')}
            >
              KU
            </button>
          </li>
        </ul>

        {/* Mobile Button */}
        <div className='flex sm:hidden z-10 items-center justify-center'>
          {/* Language buttons */}
          <button
            className={`border border-primary  font-bold w-10 h-8 textColor ${
              language === 'en' ? 'bg-primary rounded-l-md text-white' : `rounded-r-md ${nav?`text-white`:`text-${textColor}`}`
            }`}
            onClick={() => toggleLanguage('en')}
          >
            EN
          </button>

          <button
            className={`border border-primary font-bold w-10 h-8  ${
              language === 'ar' ? 'bg-primary rounded-l-md text-white' : `rounded-r-md ${nav?`text-white`:`text-${textColor}`} `
            }`}
            onClick={() => toggleLanguage('ar')}
          >
            KU
          </button>

          {/* Mobile navigation button */}
          <button onClick={handleNav} className={`${language === 'en' ? 'ml-4' : 'mr-4'}`}>
            {nav ? (
              <AiOutlineClose size={32} style={{ color: `white` }} />
            ) : (
              <AiOutlineMenu
                size={32}
                style={{ color: `${textColor}` }}
              />
            )}
          </button>
        </div>

        {/* Mobile Menu */}
        <div
          className={
            nav
              ? `sm:hidden absolute top-0 ${language === 'en' ? 'left-0' : 'right-0'} w-full h-screen bg-black text-center ease-in duration-300 flex justify-center items-center`
              : `sm:hidden absolute top-0 ${language === 'en' ? 'left-[-100%]' : 'right-[-100%]'} w-full h-screen bg-black text-center ease-in duration-300 flex justify-center items-center`
          }
        >
          <ul>
          <li className='p-4 text-lg font-bold hover:text-primary'>
          {path!='/'?<a href='/'>{texts.home}</a>: <Link onClick={() => scrollToSection('/')}>{texts.home}</Link>}
          </li>
          <li className='p-4 text-lg font-bold hover:text-primary'>
          {path!='/'?<a href='/about'>{texts.about}</a>: <Link onClick={() => scrollToSection('about')}>{texts.about}</Link>}
          </li>
          <li className='p-4 text-lg font-bold hover:text-primary'>
          {path!='/'?<a href='/'>{texts.services}</a>:<Link onClick={() => scrollToSection('services')}>{texts.services}</Link> }
          </li>

          <li className='p-4 text-lg font-bold hover:text-primary'>
          <Link onClick={() => scrollToSection('contact')}>{texts.contact}</Link>     
               </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
